<template>
    <div class="home">
        <x-header v-if="share == '0'" back>我分享的实验</x-header>
        <x-header v-if="share == '1'" back>分享给我的实验</x-header>
        <x-header v-if="share != '1' && share != '0'"  back>分享给项目的实验</x-header>
        <x-content v-if="loading" style="height:100%;" :contentStyle="{alignItems:'center',justifyContent:'center',height:'100%'}">
            <bounce-loading></bounce-loading>
        </x-content>
        <x-content v-else  :contentStyle="{display:'block',width:'calc(100% - 32px)',padding:'16px'}">
            <x-card>
                <el-table
                    :data="experimentList"
                    stripe
                    ref="reviewExperiemnt"
                    style="width: 100%">
                    <el-table-column
                        prop="experiment_id"
                        label="实验编号">
                        <template slot-scope="scope">
                              <el-tooltip content="查看实验详情" placement="bottom">
                            <el-link style='color:#409EFF' @click="$router.push('/manager/experiment/detail/'+scope.row.uuid+'/2')" :underline="false">{{scope.row.experiment_id}}</el-link>
                              </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="subject"
                        label="实验名称">
                    </el-table-column>
<!--                    <el-table-column-->
<!--                        prop="date"-->
<!--                        label="实验时间">-->
<!--                    </el-table-column>-->
                    <el-table-column
                        label="所属项目">
                        <template slot-scope="scope">
                               <span class="">{{experimentGroupIdNameMap[scope.row.group_id]}}</span> 
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="share!= '0' "
                        label="分享人">
                        <template slot-scope="scope">
                               <span class="">{{scope.row.userName}}</span> 
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="250"
                        label="属性">
                        <template slot-scope="scope">
                            <div style="display: flex; flex-direction: column;">
                                <span class="">创建时间：{{scope.row.create_time}}</span>
                                <span class="" v-if="scope.row.update_time">修改时间：{{scope.row.update_time}}</span>
                                <span class="">创建人：{{scope.row.user.name}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="100"
                        label="操作">
                        <template slot-scope="scope">
                            <action-panel :experimentObj="scope.row" :isOnlyCopy="true"></action-panel>
							<!-- <el-button  class="group_operate" size="mini" @click="$router.push('/manager/experiment/edit/'+scope.row.note.id+'/'+scope.row.form_template_id+'/'+scope.row.uuid+'/1')" type="success">复制实验</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
                <div class="page-warp">
                    <el-pagination
                        @current-change="listExperiment"
                        :hide-on-single-page="total<pageSize"
                        background
                        layout="total,prev, pager, next"
                        :total="total"
                        :page-size="pageSize"
                        :current-page.sync="page"
                    ></el-pagination>
                </div>
            </x-card>
        </x-content>
    </div>
</template>
<script>
    import {GetMyProjectList} from "@/service/user"
    import {GetShareList} from "@/service/experiment";
    let codeData = [];
    export default {
        name:'ExperimentShare',
        data(){
            return {
                loading:false,
                saveLoading:false,
                page:1,
                total:0,
                pageSize:10,
                searchText:"",
                experimentList:[],
                share:"",
                groupId:"",
                expertimentIdUserIdMap:null,
                experimentGroupIdNameMap:{},
                userIdNameMap:null,
            }
        },
        created(){
            this.share = this.$route.params.share;
            if(this.share != '0' && this.share != '1'){
                this.groupId = this.share;
                this.share = "";
            }
            this.listExperiment();
            this.getMyProjectList();

        },
        watch:{
			'$route.params.share':{
				handler:function (newVal){
                    if(newVal){
                        this.share = newVal
                        this.listExperiment();
                    }
				},
				immediate:true
			}
		},
        methods:{
            search() {
				this.page = 1;
				this.listExperiment();
			},
            goExperimentEdit(){
                this.$router.push('/manager/experiment/edit/'+this.form.note+'/'+this.form.formTemplate+'/1/0')
            },
            listExperiment(){
                this.loading = true;
                GetShareList(this.share,this.groupId,this.page,this.pageSize).then(e => {
                    let data = this.APIParse(e);
                    this.experimentList = data?.data?.hits.map(item => {
                        return item._source;
                    });
                    if(data.userIdNameMap){
                        this.userIdNameMap = data.userIdNameMap
                    }
                    if(data.expertimentIdUserIdMap){
                        this.expertimentIdUserIdMap = data.expertimentIdUserIdMap
                    }
                    this.experimentList.forEach(item => {
                        let userName = "";
                        if(this.expertimentIdUserIdMap){
                            let userId = this.expertimentIdUserIdMap[item.uuid]
                            if(this.userIdNameMap && userId){
                                userName = this.userIdNameMap[userId]?this.userIdNameMap[userId].name:"";
                            }
                        }
                        item.userName = userName
                    })
                    this.total = data.total || 0;
                }).catch(e => {
                    this.$message.error(e.message)
                }).finally(e => {
                    this.loading = false;
                })
            },
             getMyProjectList(){
                GetMyProjectList().then(e => {
                    let data = this.APIParse(e);
                    data.forEach(item => {
                      this.$set(this.experimentGroupIdNameMap,item.id,item.groupName)
                    })
                })
            },
        }
    }
</script>
<style lang="scss" scoped>
    .group_operate+.group_operate{
        margin-left: 15px;
    }
    .Width-100{width: 100%}
    .home{width: 100%;height: 100%;overflow: auto;}
    .search_warp{width:100%;margin: 16px 0px;}
    
    .save_btn_warp{width: 100%;margin-top: 32px;display: flex;align-items: center;justify-content: center;}
    .page-warp{
        text-align: center;
        margin-top: 20px;
    }
    .mySign {
        border: 1px dashed #000;
    }
</style>